const addUnkonwnOption = (options, type) => {
  if (type === 'city') {
    const firstNode = options[0];
    return options.concat([
      {
        label: 'unknown',
        value: 'unknown',
        key: `${firstNode?.countryId}-${firstNode?.provinceId}-unknown`,
      },
    ]);
  }
  return options.concat([
    {
      label: 'unknown',
      value: 'unknown',
    },
  ]);
};

export { addUnkonwnOption };
