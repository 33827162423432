import axios, { post, fetch, imgpost } from '@/api';

const BASE_URL = process.env.VUE_APP_API_IM_URL;

//查询分配规则列表
export function queryAllocationRules(pageNum,parameter) {
  return post(BASE_URL,`/api/allocate/rule/search/${pageNum}`, parameter);
}
//变更启用状态
export function updateRuleStatus(parameter) {
  return post(BASE_URL,'/api/allocate/rule/update/status', parameter);
}
//查询多娃分配的状态
export function queryChildStatus(parameter) {
  return fetch(BASE_URL,'/api/allocate/rule/find/multi-child', parameter);
}
//变更多娃启用状态
export function updateMultiChild(parameter) {
  return post(BASE_URL,'/api/allocate/rule/update/multi-child', parameter);
}
//新增分配规则
export function addRules(parameter) {
  return post(BASE_URL,'/api/allocate/rule/insert', parameter);
}
//更新分配规则
export function updateRules(parameter,uuid) {
  return post(BASE_URL,`/api/allocate/rule/update/${uuid}`, parameter);
}
//获取全部班主任列表
export function getClassAdminList(parameter) {
  return post(BASE_URL,'/api/account/ca/list', parameter);
}
//查询待分配学生列表
export function queryAssignStudents(parameter,pageNum) {
  return post(BASE_URL,`/api/allocate/assign/search/${pageNum}`, parameter);
}
//查询待分配班主任列表
export function queryAssignClassAdmin(parameter,pageNum) {
  return post(BASE_URL,`/api/allocate/assign/search/account/${pageNum}`, parameter);
}
//分配确认
export function confirmAssign(parameter) {
  return post(BASE_URL,'/api/allocate/assign/classAdmin', parameter);
}