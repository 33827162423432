import { getLocationList } from '@/api/location';
import { makeLocationNameTransformer } from './makeLocationNameTransformer';
import { systemLanguage } from '../consts';
import { addUnkonwnOption } from './addUnkonwnOption';

const getCityOptions = async (config) => {
  const { countryId, provinceId, language = systemLanguage, city, showDoubleLanguage } = config;
  if ((!countryId || !provinceId) && !city) return addUnkonwnOption([],'city');
  const { data } = await getLocationList(city ? { city } : { state: provinceId, country: countryId, city });

  if (!data) return [];

  const transformer = makeLocationNameTransformer(language);

  const cityOptions = data
    ?.filter((item) => {
      // 如果有国家和州省，需要过滤掉不匹配的城市
      if (provinceId && item.stateEn !== provinceId) {
        return false;
      }
      if (countryId && item.countryEn !== countryId) {
        return false;
      }
      // 过滤掉无效数据
      if (!item.cityEn) {
        return false;
      }

      return true;
    })
    ?.map((item) => {
      const label = transformer(item, {
        zh: 'cityZh',
        en: 'cityEn',
      });

      return {
        label: showDoubleLanguage && language === 'zh' ? `${label}(${item.cityEn})` : label,
        value: item.cityEn,
        countryId: item.countryEn,
        provinceId: item.stateEn,
        timezone: item.timezone,
        key: `${item.countryEn}-${item.stateEn}-${item.cityEn}`,
      };
    });

  return addUnkonwnOption(cityOptions,'city');
};

export { getCityOptions };
