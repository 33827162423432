<template>
  <a-tree-select
    :treeData="locationOptions"
    tree-checkable
    placeholder="搜索仅支持选择国家"
    :loadData="handleLoadData"
    @change="handleChange"
    @search="handleSearch"
    :value="targetValue"
    :show-checked-strategy="SHOW_PARENT"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    treeNodeFilterProp="title"
    
  />
</template>
<script>
import { getProvinceOptions, getCityOptions, getCountryOptions } from '@/utils/geoLocation';
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array },
  },
  data() {
    return {
      locationOptions: [],
      loading: false,
      SHOW_PARENT,
    };
  },
  computed: {
    targetValue() {
      const v = (this.value || []).map((item) => {
        return item.city ?? item.state ?? item.country;
      });
      return v;
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.range = [];
        return;
      }
      this.range = newValue;
    },
  },
  mounted() {
    this.loading = true;
    getCountryOptions()
      .then(
        (res) =>
          (this.locationOptions = res?.map((opt) => ({
            ...opt,
            isLeaf: false,
            type: 'country',
          }))),
      )
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleSearch(e) {
      console.log(e);
    },
    async handleLoadData(treeNode) {
      const targetOption = treeNode.dataRef;
      console.log('targetOption', targetOption);

      if (targetOption.type === 'country') {
        const provinceOptions = await getProvinceOptions(targetOption.value);
        targetOption.children = provinceOptions.map((item) => ({
          ...item,
          type: 'provice',
          isLeaf: false,
        }));
      }

      if (targetOption.type === 'provice') {
        const provinceOptions = await getCityOptions({
          countryId: targetOption.countryId,
          provinceId: targetOption.value,
        });
        targetOption.children = provinceOptions.map((item) => ({
          ...item,
          type: 'city',
          isLeaf: true,
        }));
      }
      this.locationOptions = [...this.locationOptions];
    },
    handleChange(v, l, extra) {
      console.log('extra', extra);
      const data = extra?.allCheckedNodes || [];
      console.log('data', data);
      const list = data
        .map((item) => {
          const dataRef = item?.node?.data?.props?.dataRef ?? item?.data?.props?.dataRef;
          return {
            country: {
              country: dataRef.value,
            },
            provice: {
              country: dataRef.countryId,
              state: dataRef.value,
            },
            city: {
              country: dataRef.countryId,
              state: dataRef.provinceId,
              city: dataRef.value,
            },
          }[dataRef.type];
        })
        .filter((item) => !!item.country);

      this.$emit('change', list);
    },
    // filter(inputValue, treeNode) {
    //   return treeNode.data.props?.label?.includes?.(inputValue);
    // },
  },
};
</script>
