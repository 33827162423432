import { getCityOptions } from './getCityOptions';
import { getCountryOptions } from './getCountryOptions';
import { getLabelFromOptions } from './getLabelFromOptions';
import { getProvinceOptions } from './getProvinceOptions';

const getLocationNames = async ({ country, province, city } = {}) => {
  const res = await Promise.allSettled(
    [
      country && getCountryOptions(),
      country && province && getProvinceOptions(country),
      country && province && city && getCityOptions({ countryId: country, provinceId: province }),
    ].filter(Boolean),
  );
  const [{ value: countryOptions } = {}, { value: provinceOptions } = {}, { value: cityOptions } = {}] = res;
  return {
    countryName: getLabelFromOptions(countryOptions, country),
    provinceName: getLabelFromOptions(provinceOptions, province),
    cityName: getLabelFromOptions(cityOptions, city),
  };
};

export { getLocationNames };
