import { getTimezoneList } from '@/api/location';

const getTimezoneOptions = async (countryId) => {
  const { data } = (await getTimezoneList({ country: countryId })) || [];

  const timezoneOptions = data?.map((item) => ({
    label: item.value,
    value: item.key,
    countryId,
  }));

  return timezoneOptions;
};

export { getTimezoneOptions };
