import { getLocationList } from '@/api/location';
import { makeLocationNameTransformer } from './makeLocationNameTransformer';
import { systemLanguage } from '../consts';
import { addUnkonwnOption } from './addUnkonwnOption';

const defaultConfig = {
  language: systemLanguage,
};

const getProvinceOptions = async (countryId, config = defaultConfig) => {
  if (!countryId) return [];
  const { language } = config;
  const { data } = await getLocationList({ country: countryId });

  const transformer = makeLocationNameTransformer(language);

  const provinceOptions = data?.map((item) => ({
    label: transformer(item, {
      zh: 'stateZh',
      en: 'stateEn',
    }),
    value: item.stateEn,
    countryId: item.countryEn,
  }));

  return addUnkonwnOption(provinceOptions);
};

export { getProvinceOptions };
