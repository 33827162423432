<template>
  <a-cascader
    :options="locationOptions"
    placeholder="请选择"
    change-on-select
    :loading="loading"
    :loadData="handleLoadData"
    @change="handleChange"
    :show-search="filter"
    :value="value"
    mode="multiple"
  />
</template>
<script>
import { getProvinceOptions, getCityOptions, getCountryOptions } from '@/utils/geoLocation';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array },
  },
  data() {
    return {
      locationOptions: [],
      loading: false,
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.range = [];
        return;
      }
      this.range = newValue;
    },
  },
  mounted() {
    this.loading = true;
    getCountryOptions()
      .then(
        (res) =>
          (this.locationOptions = res?.map((opt) => ({
            ...opt,
            isLeaf: false,
            type: 'country',
          }))),
      )
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    async handleLoadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];

      if (targetOption.type === 'country') {
        const provinceOptions = await getProvinceOptions(targetOption.value);
        targetOption.children = provinceOptions.map((item) => ({
          ...item,
          type: 'provice',
          isLeaf: false,
        }));
      }

      if (targetOption.type === 'provice') {
        const provinceOptions = await getCityOptions({
          countryId: targetOption.countryId,
          provinceId: targetOption.value,
        });
        targetOption.children = provinceOptions.map((item) => ({
          ...item,
          type: 'city',
          isLeaf: true,
        }));
      }
      this.locationOptions = [...this.locationOptions];
    },
    handleChange(v) {
      this.$emit('change', v);
    },
     filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
  },
};
</script>
