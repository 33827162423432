<template>
  <div class="dataUpload-wrap">
    <div class="table-page-search-wrapper">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="规则名称">
              <a-input v-model="ruleName" placeholder="请输入" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="科目">
              <a-select allowClear placeholder="请选择" v-model="stuSubject">
                <a-select-option :value="item.value" v-for="(item, index) in subjectArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="分配学员时间">
              <a-time-picker
                :default-open-value="$moment('00:00:00', 'HH:mm')"
                format="HH:mm"
                v-model="allocateStartTime"
                style="width: 100px"
                placeholder="开始时间"
                :inputReadOnly="true"
                @change="onStartTimeChange"
              />
              -
              <a-time-picker
                style="width: 100px"
                :default-open-value="$moment('00:00:00', 'HH:mm')"
                format="HH:mm"
                v-model="allocateEndTime"
                @change="onEndTimeChange"
                placeholder="结束时间"
                :inputReadOnly="true"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <div class="sel-navwrap">
        <span class="switch-wrap"
          >多娃优先分配 <a-switch :checked="multiChildStatus === 'ACTIVE'" @click="changeChildSwitch"
        /></span>
        <a-button type="primary" ghost @click="addRules"> 新增分配规则 </a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
        @change="handleTableChange"
        :loading="loading"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="allocateStartTime" slot-scope="allocateStartTime, record">
          <span> {{ allocateStartTime }} - {{ record.allocateEndTime }}</span>
        </template>
        <template slot="whoModified" slot-scope="whoModified, record">
          <span>{{
            whoModified && whoModified.substring(whoModified.indexOf('(') + 1, whoModified.indexOf(')'))
          }}</span>
        </template>
        <template slot="stuSubject" slot-scope="stuSubject, record">
          <span v-for="(item, index) in stuSubject" :key="index"
            >{{ getDdlLabel(subjectArr, stuSubject[index])
            }}<span v-if="stuSubject.length !== index + 1">、</span></span
          >
        </template>
        <template slot="ruleStatus" slot-scope="ruleStatus, record">
          <a-switch
            :checked="ruleStatus === 'ACTIVE'"
            size="small"
            @click="(checked) => changeSwitch(checked, record)"
          />
        </template>
        <template slot="operate" slot-scope="operate, record">
          <span style="color: #04cb94; cursor: pointer" @click="editFun(record)">编辑</span>
        </template>
      </a-table>
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      width="680px"
      :maskClosable="false"
      @cancel="onCancel"
      @ok="submitRules"
      :okButtonProps="{
        props: {
          loading: buttonLoading,
        },
      }"
    >
      <div class="form-wrap modal-height">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <div class="tab-nav">
            <span style="font-size: 14px; font-weight: bold">第一步：规则基本信息</span>
            <!-- <a-icon
            type="down"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showRule')"
            v-show="!showRule"
          />
          <a-icon
            type="up"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showRule')"
            v-show="showRule"
          /> -->
          </div>
          <div v-show="showRule">
            <a-form-item label="规则名称">
              <a-input
                allowClear
                :maxLength="100"
                v-decorator="['ruleName', { rules: [{ required: true, message: '请输入规则名称!' }] }]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="规则状态">
              <a-radio-group v-decorator="['ruleStatus', { rules: [{ required: true, message: '请选择规则状态!' }] }]">
                <a-radio value="ACTIVE"> 开启 </a-radio>
                <a-radio value="INACTIVE"> 禁用 </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="规则优先级">
              <a-input-number style="width: 100%" v-decorator="['rulePriority']" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="分配学员时间" :validateStatus="validateStatus" :help="help">
              <a-time-picker
                :default-open-value="$moment('00:00:00', 'HH:mm')"
                format="HH:mm"
                v-decorator="['allocateStartTime', validatorRules.allocateStartTime]"
                style="width: 100px"
                placeholder="开始时间"
                :inputReadOnly="true"
                @change="onAllocateStartTimeChange"
              />
              -
              <a-time-picker
                style="width: 100px"
                :default-open-value="$moment('00:00:00', 'HH:mm')"
                format="HH:mm"
                v-decorator="['allocateEndTime', validatorRules.allocateEndTime]"
                placeholder="结束时间"
                :inputReadOnly="true"
                @change="onAllocateEndTimeChange"
              />
            </a-form-item>
          </div>
          <div class="tab-nav">
            <span style="font-size: 14px; font-weight: bold">第二步：学员要求</span>
            <!-- <a-icon
            type="down"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showStudent')"
            v-show="!showStudent"
          />
          <a-icon
            type="up"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showStudent')"
            v-show="showStudent"
          /> -->
          </div>
          <div>
            <a-form-item label="学生类型">
              <a-select
                v-decorator="['stuType', { rules: [{ required: true, message: '请选择学生类型!' }] }]"
                placeholder="请选择学生类型"
              >
                <a-select-option v-for="(item, index) in studentTypeArr" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="学员科目">
              <a-select mode="multiple" v-decorator="['stuSubject']" placeholder="请选择">
                <a-select-option v-for="(item, index) in subjectArr" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="学生时区">
              <a-select
                show-search
                mode="multiple"
                placeholder="请选择"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['stuTimezone']"
              >
                <a-select-option :value="i.value" v-for="(i, index) of timezoneOptions" :key="i.value">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="学生国家/地区">
              <a-select
                show-search
                mode="multiple"
                placeholder="学生国家/地区"
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleCountryChange"
                v-decorator="['stuResidentCountry', { rules: [{ required: true, message: '请选择国家/地区!' }] }]"
              >
                <a-select-option :value="i.value" v-for="(i, index) of countryOptions" :key="index">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <!-- <a-form-item label="学生国家/地区">
              <a-select
                show-search
                mode="multiple"
                placeholder="学生国家/地区"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['stuResidentCountry']"
              >
                <a-select-option :value="i.name" v-for="(i, index) of countries" :key="i.label">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </a-form-item> -->

            <a-form-item label="学生沟通语言">
              <a-select placeholder="请选择" mode="multiple" v-decorator="['stuCommunicationLanguage']">
                <a-select-option :value="i.value" v-for="(i, index) of languageArr" :key="index">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="服务渠道">
              <a-select placeholder="请选择" mode="multiple" v-decorator="['serviceChannel']">
                <a-select-option
                  :value="value"
                  v-for="[value, label] of Object.entries(ServiceChannelEnumText)"
                  :key="value"
                >
                  {{ label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div class="tab-nav">
            <span style="font-size: 14px; font-weight: bold">第三步：分配班主任要求</span>
            <!-- <a-icon
            type="down"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showFlagInfo')"
            v-show="!showFlagInfo"
          />
          <a-icon
            type="up"
            style="font-size: 16px; color: #999; float: right"
            @click="toggleFLag('showFlagInfo')"
            v-show="showFlagInfo"
          /> -->
          </div>
          <div v-show="showFlagInfo">
            <a-form-item label="分配对象类型">
              <a-radio-group
                @change="changeAssignType"
                v-decorator="['allocateTargetType', { rules: [{ required: true, message: '请选择分配对象类型!' }] }]"
              >
                <a-radio value="DEPT"> 团队</a-radio>
                <a-radio value="PERSON"> 个人</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="分配对象" v-show="assignFlag === 'PERSON'">
              <a-select
                show-search
                mode="multiple"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                allow-clear
                v-decorator="['allocateIds', { rules: [{ required: true, message: '请选择分配对象!' }] }]"
              >
                <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                  {{ i.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="分配对象" v-show="assignFlag === 'DEPT'">
              <a-tree-select
                tree-checkable
                v-decorator="['allocateIds', { rules: [{ required: true, message: '请选择分配对象!' }] }]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                placeholder="请选择"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="每月限额">
              <a-radio-group
                v-decorator="['allocateMonthLimit', { rules: [{ required: true, message: '请选择每月限额!' }] }]"
              >
                <a-radio :value="false"> 不受限制</a-radio>
                <a-radio :value="true">受数量限制</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="每日限额">
              <a-radio-group
                v-decorator="['allocateDayLimit', { rules: [{ required: true, message: '请选择每日限额!' }] }]"
              >
                <a-radio :value="false"> 不受限制</a-radio>
                <a-radio :value="true">受数量限制</a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  queryAllocationRules,
  updateRuleStatus,
  queryChildStatus,
  updateMultiChild,
  addRules,
  updateRules,
  getClassAdminList,
} from '@/api/set';
import { countries, getTreeList } from '@/api/headTeacher';
import { getDdlLabel } from '@/utils/util';
import { getCountryOptions, getTimezoneOptions } from '@/utils/geoLocation';
import { ServiceChannelEnumText } from '@/enum/index';

export default {
  name: 'allocationRules',
  data() {
    return {
      ServiceChannelEnumText,
      getDdlLabel,
      help: '',
      timezoneOptions: [],
      countryOptions: [],
      // 校验规则
      validatorRules: {
        allocateStartTime: { rules: [{ required: true, message: '请选择分配学员开始时间!' }] },
        allocateEndTime: { rules: [{ required: true, message: '请选择分配学员结束时间!' }] },
      },
      validateStatus: '',
      updateId: '',
      modalTitle: '新增规则',
      assignStartTime: null,
      assignEndTime: null,
      assignFlag: '',
      buttonLoading: false,
      treeData: [],
      classAdminList: [],
      languageArr: [
        { label: '中文', value: '中文' },
        { label: '中文(粤语)', value: '中文(粤语)' },
        { label: '英语', value: '英语' },
        { label: '日语', value: '日语' },
        { label: '韩语', value: '韩语' },
        { label: '法语', value: '法语' },
        { label: '德语', value: '德语' },
        { label: '印尼语', value: '印尼语' },
        { label: '其他', value: '其他' },
      ],
      studentTypeArr: [
        { label: '新生', value: 'NEW' },
        { label: '老生', value: 'OLD' },
      ],
      showStudent: true,
      showRule: true,
      showFlagInfo: true,
      form: this.$form.createForm(this),
      countries: null,
      visible: false,
      endTime: null,
      startTime: null,
      multiChildStatus: '',
      allocateEndTime: null,
      allocateStartTime: null,
      classDateArr: [],
      ruleName: '',
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      stuSubject: undefined,
      searchMonth: null,
      uploadType: '',
      fileName: '',
      loading: false,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '规则名称',
          dataIndex: 'ruleName',
          key: 'ruleName',
        },
        {
          title: '科目',
          dataIndex: 'stuSubject',
          key: 'stuSubject',
          scopedSlots: { customRender: 'stuSubject' },
        },

        {
          title: '规则优先级',
          dataIndex: 'rulePriority',
          key: 'rulePriority',
        },
        {
          title: '分配学员时段',
          dataIndex: 'allocateStartTime',
          key: 'allocateStartTime',
          scopedSlots: { customRender: 'allocateStartTime' },
        },
        {
          title: '最近操作人',
          dataIndex: 'whoModified',
          key: 'whoModified',
          scopedSlots: { customRender: 'whoModified' },
        },
        {
          title: '最近更新时间',
          dataIndex: 'whenModified',
          key: 'whenModified',
          width: 220,
        },
        {
          title: '启用状态',
          dataIndex: 'ruleStatus',
          key: 'ruleStatus',
          scopedSlots: { customRender: 'ruleStatus' },
        },
        {
          title: '操作',
          dataIndex: 'operate',
          key: 'operate',
          scopedSlots: { customRender: 'operate' },
        },
      ],

      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      pageSize: 10,
      current: 0,
      tableData: [],

      uploadTypeArr: [
        { label: '学员分层', value: 'STUDENT_RANK' },
        { label: '班主任业务数据', value: 'CLASSADMIN_PERFORMANCE' },
        { label: '班主任每月限额', value: 'CLASSADMIN_MONTH_LIMIT' },
      ],
    };
  },
  methods: {
    editFun(values) {
      console.log(values);

      this.updateId = values.uuid;
      this.modalTitle = '更新规则';
      this.visible = true;
      this.$nextTick(() => {
        this.assignStartTime = values.allocateStartTime;
        this.assignEndTime = values.allocateEndTime;
        this.assignFlag = values.allocateTargetType;
        this.form.setFieldsValue({
          ruleName: values.ruleName,
          ruleStatus: values.ruleStatus,
          rulePriority: values.rulePriority,
          allocateStartTime: this.$moment(values.allocateStartTime, 'HH:mm'),
          allocateEndTime: this.$moment(values.allocateEndTime, 'HH:mm'),
          allocateTargetType: values.allocateTargetType,
          allocateIds: values.allocateIds || [],
          allocateMonthLimit: values.allocateMonthLimit,
          allocateDayLimit: values.allocateDayLimit,
          stuTimezone: values.stuTimezone || [],
          stuType: values.stuType,
          stuSubject: values.stuSubject || [],
          stuResidentCountry: values.stuResidentCountry || [],
          stuCommunicationLanguage: values.stuCommunicationLanguage || [],
          serviceChannel: values.serviceChannel || [],
        });
      });
    },
    onAllocateStartTimeChange(date) {
      this.assignStartTime = date == null ? null : date.format('HH:mm');
      if (this.assignStartTime) {
        // 如果没有报错的情况
        this.validateStatus = 'success';
        this.help = '';
      }
    },
    onAllocateEndTimeChange(date) {
      this.assignEndTime = date == null ? null : date.format('HH:mm');
      if (this.assignEndTime) {
        // 如果没有报错的情况
        this.validateStatus = 'success';
        this.help = '';
      }
    },
    changeAssignType(e) {
      this.form.resetFields('allocateIds');
      this.assignFlag = e.target.value;
    },
    submitRules() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.rulePriority && values.rulePriority < 0) {
            this.$message.warning('规则优先级最小不能小于0');
            return false;
          }
          this.buttonLoading = true;

          const params = {
            ruleName: values.ruleName,
            ruleStatus: values.ruleStatus,
            rulePriority: values.rulePriority,
            allocateStartTime: this.assignStartTime,
            allocateEndTime: this.assignEndTime,
            allocateTargetType: values.allocateTargetType,
            allocateIds: values.allocateIds,
            allocateMonthLimit: values.allocateMonthLimit,
            allocateDayLimit: values.allocateDayLimit,

            stuParam: [
              { fieldName: 'stuType', value: values.stuType, condition: 'EQ' },
              { fieldName: 'stuSubject', value: values.stuSubject, condition: 'CONTAIN' },
              { fieldName: 'stuTimezone', value: values.stuTimezone, condition: 'CONTAIN' },
              { fieldName: 'stuResidentCountry', value: values.stuResidentCountry, condition: 'CONTAIN' },
              { fieldName: 'stuCommunicationLanguage', value: values.stuCommunicationLanguage, condition: 'CONTAIN' },
              { fieldName: 'serviceChannel', value: values.serviceChannel, condition: 'CONTAIN' },
            ],
          };
          if (this.modalTitle === '新增规则') {
            addRules(params)
              .then((res) => {
                this.$message.success('提交成功');
                this.initSearchData();
                this.onCancel();
              })
              .catch(() => {
                this.buttonLoading = false;
              });
          } else {
            // 更新规则
            updateRules(params, this.updateId)
              .then((res) => {
                this.$message.success('更新成功');
                this.initSearchData();
                this.onCancel();
              })
              .catch(() => {
                this.buttonLoading = false;
              });
          }
        } else {
          // 校验失败，自定义校验信息
          // 这边三个值依次取值，返回第一个有错误信息的属性
          const onErr = err.allocateStartTime || err.allocateEndTime;
          if (onErr && onErr.errors) {
            // 如果有错误信息，设置错误状态
            this.validateStatus = 'error';
            // 一个错误信息内部是校验内容是以数组的形式返回的，可以在data.validatorRules中的rules中看到顺序
            this.help = onErr.errors[0].message;
          } else {
            // 如果没有报错的情况
            this.validateStatus = 'success';
            this.help = '';
          }
        }
      });
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    getClassAdminList() {
      getClassAdminList({
        roles: ['CLASS_ADMIN'],
      }).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    toggleFLag(flag) {
      this[flag] = !this[flag];
    },
    addRules() {
      this.visible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ruleStatus: 'ACTIVE',
          allocateMonthLimit: true,
          allocateDayLimit: true,
        });
      });
    },
    onCancel() {
      this.visible = false;
      this.buttonLoading = false;
      this.assignFlag = '';
      this.assignStartTime = null;
      this.assignEndTime = null;
      this.modalTitle = '新增规则';
      this.form.resetFields();
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    unique(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i].areaCode);
        }
      }
      return arr1;
    },
    onStartTimeChange(date) {
      this.startTime = date == null ? null : date.format('HH:mm');
    },
    onEndTimeChange(date) {
      this.endTime = date == null ? null : date.format('HH:mm');
    },
    changeChildSwitch(checked) {
      const params = {
        status: checked ? 'ACTIVE' : 'INACTIVE',
      };
      updateMultiChild(params).then((res) => {
        this.multiChildStatus = checked ? 'ACTIVE' : 'INACTIVE';
        this.$message.success('修改状态成功');
      });
    },
    changeSwitch(checked, record) {
      const params = {
        uuid: record.uuid,
        status: checked ? 'ACTIVE' : 'INACTIVE',
      };
      updateRuleStatus(params).then((res) => {
        this.$message.success('修改状态成功');
        this.initSearchData();
      });
    },

    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        ruleName: this.ruleName,
        stuSubject: this.stuSubject,
        allocateStartTime: this.startTime,
        allocateEndTime: this.endTime,
        pageSize: this.pageSize,
      };
      this.loading = true;
      queryAllocationRules(this.pageNum, params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.content;
          console.log(this.tableData);

          this.current = res.data.currentPage;
          this.pageSize = res.data.pageSize;
          this.initPagination.total = res.data.totalCount;
          this.initPagination.current = res.data.currentPage;
          this.initPagination.pageSize = res.data.pageSize;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleTableChange(data) {
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.initSearchData();
    },

    resetCondition() {
      this.allocateStartTime = null;
      this.allocateEndTime = null;
      this.startTime = null;
      this.endTime = null;
      this.stuSubject = undefined;
      this.ruleName = undefined;
      this.initSearchData('search');
    },
  },
  created() {
    getCountryOptions().then((data) => (this.countryOptions = data));
    getTimezoneOptions().then((data) => (this.timezoneOptions = data));

    this.getClassAdminList();
    this.getTreeList();
    countries().then((i) => {
      this.countries = i.data.content;
      this.areaCodeArr = this.unique(i.data.content).filter((item) => {
        return item !== '+86';
      });
    });
  },
  mounted() {
    queryChildStatus().then((res) => {
      this.multiChildStatus = res.data.content;
    });
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 16px;
}
.modal-height {
  height: 508px;
  overflow-y: auto;
}
.tab-nav {
  background: #f7f7f7;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.switch-wrap {
  padding: 5px 12px;
  background-color: #f4f4f4;
  display: inline-block;
  margin-right: 15px;
  border-radius: 5px;
}
.sel-navwrap {
  text-align: right;
  margin-bottom: 20px;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  text-align: right;
  margin-bottom: 20px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
.progress {
  text-align: center;
}
.progress-txt {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin-top: 12px;
}
.progress-txt1 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
}
.error-pic {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.errorTxt {
  color: red;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
</style>
